const BASE_URL = "https://api.clevermail.co";

const REL_AUTH_URL = `auth`;
const REL_EMAIL_URL = `email`;
const REL_PROFILE_URL = `profile`;
const REL_USER_URL = `user`;
const REL_REDIRECT_PAYMENT_URL = `payment-finished`;
const ABS_AUTH_URL = `${BASE_URL}/${REL_AUTH_URL}`;
const ABS_EMAIL_URL = `${BASE_URL}/${REL_EMAIL_URL}`;
const ABS_PROFILE_URL = `${BASE_URL}/${REL_PROFILE_URL}`;

const APP_BASE_URL = "https://app.clevermail.co";
const SIGN_IN_URL = `${APP_BASE_URL}/#/signin`;
const SETTINGS_CUSTOMIZATIONS_URL = `${APP_BASE_URL}/#/settings/ai-customizations`;
const PAYMENT_REDIRECT_URL = `${APP_BASE_URL}/#/${REL_REDIRECT_PAYMENT_URL}`;
const SETTINGS_URL = `${APP_BASE_URL}/#/settings/get-started`;
const HOME_URL = `${APP_BASE_URL}/#/`;
const STRIPE_KEY =
  "pk_live_51ORMxBGYJkaq5ZjbBNikkgulvCOGv7q5PXwOJW75wImYDD4sWqQ6J7xfcTjBhLnrdqp7L7vIJ51JsGkTx9uEKPGa00HDQCNj2A";
const RUDDERSTACK_WRITE_KEY = "2fsutsmKVRvVMgxxzchqjS61SB7";
const VIRAL_LOOPS_CAMPAIGN_ID = "kEEQML8wJ6UJ8MroCabcEFC0h1Y";
const CAPTCHA_KEY_V3 = "6LcedQQqAAAAAC6-rH7sw91fy_Gl3NQMJEpjwE19";
const CAPTCHA_KEY_V2 = "6LfZsgYqAAAAAEDdabbQwKo-UVi3xRBgUubYC_tQ";

export {
  CAPTCHA_KEY_V2,
  CAPTCHA_KEY_V3,
  VIRAL_LOOPS_CAMPAIGN_ID,
  RUDDERSTACK_WRITE_KEY,
  SIGN_IN_URL,
  BASE_URL,
  SETTINGS_CUSTOMIZATIONS_URL,
  PAYMENT_REDIRECT_URL,
  REL_REDIRECT_PAYMENT_URL,
  ABS_AUTH_URL,
  ABS_EMAIL_URL,
  ABS_PROFILE_URL,
  REL_AUTH_URL,
  REL_EMAIL_URL,
  REL_PROFILE_URL,
  REL_USER_URL,
  APP_BASE_URL,
  SETTINGS_URL,
  HOME_URL,
  STRIPE_KEY,
};
